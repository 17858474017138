<template>
  <section class="logo text-center">
    <img :src="src" :alt="description" height="51" width="200" loading="lazy" />
  </section>
</template>

<script>
import packetaLogoEn from "../assets/packeta-logo.svg";
import packetaLogoCs from "../assets/packeta-logo-cs@2x.jpg";
import getLanguage from "../util/language";

export default {
  name: "PacketaLogo",
  props: ["description"],
  data() {
    return {
      src: packetaLogoEn
    };
  },

  created() {
    const lang = getLanguage();

    this.src = this.resolveImage(lang);
  },

  methods: {
    resolveImage(lang) {
      return lang === "cs" ? packetaLogoCs : packetaLogoEn;
    }
  }
};
</script>

<style lang="scss" scoped>
.logo > img {
  object-fit: cover;
  object-position: center;
}
</style>
